<template>
  <ion-page>
    <ion-header>
      <BaseToolbar :page-title="$t('views.home')" show-app-name />
    </ion-header>
    <ion-content>
      <BaseContent class="home">
        <TokenBlockPromoted
          class="home__token-block home__token-block--promoted"
        >
          <TokenItem
            v-if="isLoading && !tokenList"
            :state-loading="isLoading"
          />
          <template v-else-if="tokenList && tokenList.promotedToken">
            <TokenItem
              v-for="(token, index) in tokenList.promotedToken"
              :key="index"
              :token="token"
              :count="getVoteCountForToken(token)"
              :show-since-launch-date="true"
              @click="detailToken(token)"
            />
            <p
              v-text="$t('global.empty')"
              v-if="
                (!tokenList.promotedToken ||
                  tokenList.promotedToken.length === 0) &&
                !isLoading
              "
            />
          </template>
        </TokenBlockPromoted>
        <TokenBlock
          :active-token-list="tokenList ? tokenList[tokenBlockFilter] : []"
          class="home__token-block"
          v-model="tokenBlockFilter"
          @detail="detailToken($event)"
        >
          <TokenItem
            v-if="isLoading && !tokenList"
            :state-loading="isLoading"
          />
        </TokenBlock>
      </BaseContent>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  WritableComputedRef,
} from 'vue';
import BaseToolbar from '@/components/base/BaseToolbar.vue';
import BaseContent from '@/components/base/BaseContent.vue';
import TokenItem from '@/components/token-item/TokenItem.vue';
import TokenBlockPromoted from '@/components/token-block/TokenBlockPromoted.vue';
import TokenBlock from '@/components/token-block/TokenBlock.vue';
import { IonPage, IonContent, IonHeader } from '@ionic/vue';
import useStore from '@/store';
import { ActionTypes } from '@/store/modules/token/actions';
import { MutationTypes } from '@/store/modules/token/mutations';
import { Token, TokenList } from '@/interfaces/token';
import { useRouter } from 'vue-router';
import { useVote } from '@/hooks/useVote';
import { RouteName } from '@/router/route-const';

export default defineComponent({
  name: 'Home',
  components: {
    TokenBlock,
    BaseToolbar,
    BaseContent,
    TokenItem,
    TokenBlockPromoted,
    IonPage,
    IonContent,
    IonHeader,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const { getVoteCountForToken } = useVote();

    const isLoading = computed(() => store.getters.isLoading);

    const tokenBlockFilter = ref<
      'allToken' | 'promotedToken' | 'allTime' | 'new' | 'today'
    >('today');

    const tokenList: WritableComputedRef<TokenList | undefined> = computed(
      () => store.getters.tokenList
    );

    onMounted(() => {
      store.commit(MutationTypes.CURRENT_TOKEN, undefined);
      store.dispatch(ActionTypes.setTokenList);
    });

    const detailToken = async (currentToken: Token) => {
      if (!currentToken._id) return;
      await router.push({
        name: RouteName.DETAIL_TOKEN,
        params: { id: currentToken._id },
      });
    };

    return {
      isLoading,
      tokenBlockFilter,
      tokenList,

      detailToken,
      getVoteCountForToken,
    };
  },
});
</script>

<style lang="scss" scoped>
.home {
  &__token-block {
    &:not(:last-child) {
      margin-bottom: 2em;
    }
  }
}
</style>
