
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  WritableComputedRef,
} from 'vue';
import BaseToolbar from '@/components/base/BaseToolbar.vue';
import BaseContent from '@/components/base/BaseContent.vue';
import TokenItem from '@/components/token-item/TokenItem.vue';
import TokenBlockPromoted from '@/components/token-block/TokenBlockPromoted.vue';
import TokenBlock from '@/components/token-block/TokenBlock.vue';
import { IonPage, IonContent, IonHeader } from '@ionic/vue';
import useStore from '@/store';
import { ActionTypes } from '@/store/modules/token/actions';
import { MutationTypes } from '@/store/modules/token/mutations';
import { Token, TokenList } from '@/interfaces/token';
import { useRouter } from 'vue-router';
import { useVote } from '@/hooks/useVote';
import { RouteName } from '@/router/route-const';

export default defineComponent({
  name: 'Home',
  components: {
    TokenBlock,
    BaseToolbar,
    BaseContent,
    TokenItem,
    TokenBlockPromoted,
    IonPage,
    IonContent,
    IonHeader,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const { getVoteCountForToken } = useVote();

    const isLoading = computed(() => store.getters.isLoading);

    const tokenBlockFilter = ref<
      'allToken' | 'promotedToken' | 'allTime' | 'new' | 'today'
    >('today');

    const tokenList: WritableComputedRef<TokenList | undefined> = computed(
      () => store.getters.tokenList
    );

    onMounted(() => {
      store.commit(MutationTypes.CURRENT_TOKEN, undefined);
      store.dispatch(ActionTypes.setTokenList);
    });

    const detailToken = async (currentToken: Token) => {
      if (!currentToken._id) return;
      await router.push({
        name: RouteName.DETAIL_TOKEN,
        params: { id: currentToken._id },
      });
    };

    return {
      isLoading,
      tokenBlockFilter,
      tokenList,

      detailToken,
      getVoteCountForToken,
    };
  },
});
