<template>
  <div class="token-block">
    <div class="token-block__list-header">
      <span
        class="token-block__list-header-title"
        v-text="$t('token-block.all-tokens')"
      />

      <ion-segment
        mode="ios"
        :value="modelValue"
        @ionChange="$emit('update:modelValue', $event.target.value)"
      >
        <ion-segment-button value="today">
          <ion-label v-text="$t('token-block.today')" />
        </ion-segment-button>
        <ion-segment-button value="allTime">
          <ion-label v-text="$t('token-block.all-time')" />
        </ion-segment-button>
        <ion-segment-button value="new">
          <ion-label v-text="$t('token-block.new')" />
        </ion-segment-button>
      </ion-segment>
    </div>

    <div class="token-block__list-wrapper">
      <TokenBlockListHeader @search="doSearch($event)" />
      <ion-list class="token-block__list">
        <slot />
        <TokenItem
          v-for="(token, index) in tokenList"
          :key="index"
          :token="token"
          :show-since-launch-date="true"
          :count="getVoteCountForToken(token)"
          @click="$emit('detail', token)"
        />
        <p
          v-text="$t('global.empty')"
          v-if="(!tokenList || tokenList.length === 0) && !isLoading"
        />
      </ion-list>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref, watch } from 'vue';
import { IonList, IonSegment, IonSegmentButton, IonLabel } from '@ionic/vue';
import TokenItem from '@/components/token-item/TokenItem.vue';
import TokenBlockListHeader from '@/components/token-block/TokenBlockListHeader.vue';
import { Token } from '@/interfaces/token';
import useStore from '@/store';
import { useVote } from '@/hooks/useVote';

export default defineComponent({
  name: 'TokenBlock',
  emits: ['update:modelValue', 'detail'],
  components: {
    IonList,
    IonSegment,
    IonSegmentButton,
    IonLabel,
    TokenItem,
    TokenBlockListHeader,
  },
  props: {
    modelValue: { type: String },
    activeTokenList: {
      type: Array as PropType<Token[]>,
      default: () => [],
    },
  },
  setup(props) {
    const store = useStore();
    const { getVoteCountForToken } = useVote();

    const isLoading = computed(() => store.getters.isLoading);

    const tokenList = ref(props.activeTokenList);

    const doSearch = (value: string) => {
      if (!value || value.length === 0) {
        tokenList.value = props.activeTokenList;
        return;
      }

      tokenList.value = tokenList.value.filter(
        (c: Token) =>
          c.label.toLowerCase().includes(value.toLowerCase()) ||
          c.description.toLowerCase().includes(value.toLowerCase()) ||
          c.symbol.toLowerCase().includes(value.toLowerCase()) ||
          c.chain.toLowerCase().includes(value.toLowerCase())
      );
    };

    watch(
      () => props.activeTokenList,
      (activeTokenList) => {
        tokenList.value = activeTokenList;
      }
    );

    return {
      isLoading,
      tokenList,

      doSearch,
      getVoteCountForToken,
    };
  },
});
</script>

<style lang="scss" scoped>
.token-block {
}
</style>
