
import { defineComponent } from 'vue';
import { IonItem, IonSearchbar } from '@ionic/vue';

export default defineComponent({
  name: 'TokenBlockListHeader',
  emits: ['search'],
  components: {
    IonItem,
    IonSearchbar,
  },
});
