
import { defineComponent } from 'vue';
import { IonList, IonButton } from '@ionic/vue';
import { useRouter } from 'vue-router';
import { RouteName } from '@/router/route-const';

export default defineComponent({
  name: 'TokenBlockPromoted',
  components: {
    IonList,
    IonButton,
  },
  setup() {
    const router = useRouter();

    const promoteMyToken = () => {
      router.push({ name: RouteName.PROMOTE });
    };

    return { promoteMyToken };
  },
});
