
import { computed, defineComponent, PropType, ref, watch } from 'vue';
import { IonList, IonSegment, IonSegmentButton, IonLabel } from '@ionic/vue';
import TokenItem from '@/components/token-item/TokenItem.vue';
import TokenBlockListHeader from '@/components/token-block/TokenBlockListHeader.vue';
import { Token } from '@/interfaces/token';
import useStore from '@/store';
import { useVote } from '@/hooks/useVote';

export default defineComponent({
  name: 'TokenBlock',
  emits: ['update:modelValue', 'detail'],
  components: {
    IonList,
    IonSegment,
    IonSegmentButton,
    IonLabel,
    TokenItem,
    TokenBlockListHeader,
  },
  props: {
    modelValue: { type: String },
    activeTokenList: {
      type: Array as PropType<Token[]>,
      default: () => [],
    },
  },
  setup(props) {
    const store = useStore();
    const { getVoteCountForToken } = useVote();

    const isLoading = computed(() => store.getters.isLoading);

    const tokenList = ref(props.activeTokenList);

    const doSearch = (value: string) => {
      if (!value || value.length === 0) {
        tokenList.value = props.activeTokenList;
        return;
      }

      tokenList.value = tokenList.value.filter(
        (c: Token) =>
          c.label.toLowerCase().includes(value.toLowerCase()) ||
          c.description.toLowerCase().includes(value.toLowerCase()) ||
          c.symbol.toLowerCase().includes(value.toLowerCase()) ||
          c.chain.toLowerCase().includes(value.toLowerCase())
      );
    };

    watch(
      () => props.activeTokenList,
      (activeTokenList) => {
        tokenList.value = activeTokenList;
      }
    );

    return {
      isLoading,
      tokenList,

      doSearch,
      getVoteCountForToken,
    };
  },
});
