<template>
  <div class="token-block">
    <div class="token-block__list-header">
      <span
        class="token-block__list-header-title"
        v-text="$t('token-block.promoted-token')"
      />
      <ion-button
        color="primary"
        fill="outline"
        size="small"
        v-text="$t('token-block.promote-my-token')"
        @click="promoteMyToken"
      />
    </div>

    <div class="token-block__list-wrapper">
      <ion-list class="token-block__list">
        <slot />
      </ion-list>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { IonList, IonButton } from '@ionic/vue';
import { useRouter } from 'vue-router';
import { RouteName } from '@/router/route-const';

export default defineComponent({
  name: 'TokenBlockPromoted',
  components: {
    IonList,
    IonButton,
  },
  setup() {
    const router = useRouter();

    const promoteMyToken = () => {
      router.push({ name: RouteName.PROMOTE });
    };

    return { promoteMyToken };
  },
});
</script>
