<template>
  <ion-item class="token-block-list-header" lines="none">
    <div class="token-block-list-header__left-col">
      <ion-searchbar
        mode="ios"
        :placeholder="$t('token-block.search-placeholder')"
        @ionChange="$emit('search', $event.target.value)"
      />
    </div>
    <div class="token-block-list-header__right-col">
      <span
        class="token-block-list-header__since-launch-date"
        v-text="$t('token-block.since-launch')"
      />
      <span
        class="token-block-list-header__vote-label"
        v-text="$t('token-block.votes')"
      />
    </div>
  </ion-item>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { IonItem, IonSearchbar } from '@ionic/vue';

export default defineComponent({
  name: 'TokenBlockListHeader',
  emits: ['search'],
  components: {
    IonItem,
    IonSearchbar,
  },
});
</script>

<style lang="scss">
.token-block-list-header {
  --padding-start: 0;
  display: flex;
  justify-content: space-between;
  --background: var(--ion-color-light);
  color: var(--ion-color-dark);

  &__left-col,
  &__right-col {
    flex: 1;
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    grid-gap: 0.5em;
  }

  &__right-col {
    margin-left: 1em;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 'since-launch-date vote-label';
    text-align: center;
    word-break: break-word;
  }

  &__since-launch-date {
    grid-area: since-launch-date;
  }
  &__vote-label {
    grid-area: vote-label;
  }

  &__since-launch-date,
  &__vote-label {
    font-weight: var(--fw-bold);
    font-size: 0.5em;
  }
}
</style>
